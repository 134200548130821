import styled from '@emotion/styled'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { Shimmer } from '@platform-ui/components/Shimmer'
import { Body16 } from '@platform-ui/typography'

export const Title = styled.h3(({ theme }) => ({
  paddingLeft: '12px',
  ...theme.typography['headline/20'],
  marginBottom: '20px',

  [`${theme.media.md}`]: {
    ...theme.typography['headline/28'],
    marginBottom: '20px',
  },
}))

export const RowCustom = styled(Row)(({ theme }) => {
  return {
    marginBottom: '16px',
  }
})

export const ColCustom = styled(Col)(({ theme }) => {
  return {
    marginBottom: '16px',
  }
})

export const TransferShimmer = styled(Shimmer)(() => ({
  marginBottom: 10,
  borderRadius: 10,
  height: 140,
  width: '100%',
}))
export const TransferTitleShimmer = styled(Shimmer)(({ theme }) => ({
  marginBottom: 10,
  borderRadius: 16,
  height: 40,
  width: '100%',
  [`${theme.media.md}`]: {
    height: 56,
    borderRadius: 8,
  },
}))

export const ErrorWrapper = styled.div(() => ({
  padding: 12,
  margin: '16px 0',
  textAlign: 'center',
  with: '100%',
}))

export const ErrorText = styled(Body16)(({ theme }) => ({
  fontSize: 14,
  lineHeight: '18px',
  textAlign: 'center',
  marginTop: 8,
  color: theme.color['text/secondary'],
}))
