import React, { useState, forwardRef, useRef, useEffect } from 'react'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { SearchList } from './components/SearchList'
import { InputSearch } from './components/InputSearch'
import { useForkRef } from '@platform-ui/utils'
import { BeneficiariesModel } from '@/api/pms/beneficiaries/BeneficiariesModel'
import { TRANSACTION_TYPE } from '@/features/transfers-list/constants'
import { useObserver } from '@/hooks/useObserver'

export type SearchBeneficiariesProps = Readonly<{ isDisabled?: boolean }>

const INITIAL_VALUE = ''

export const SearchBeneficiaries = forwardRef(
  ({ isDisabled }: SearchBeneficiariesProps, ref): any => {
    const context = useAppContext<AppContext>()
    const [value, setValue] = useState(INITIAL_VALUE)

    const [isOpenList, setIsOpenList] = useState(false)
    const [isScrolling, setIsScrolling] = useState(false)
    const handleClosePopover = () => setIsOpenList(false)

    const elemRef = useRef<HTMLDivElement>()
    const rootRef = useForkRef(elemRef, ref)

    const { isVisible } = useObserver(elemRef, { root: null, rootMargin: '0px 0px -360px 0px' })

    const handleClick = (item: BeneficiariesModel.Beneficiary) => {
      handleClosePopover()
      context.eventBus.transfersModal.open.emit({
        type: TRANSACTION_TYPE.payments,
        title: item.categoryName,
        beneficiaryId: item.beneficiaryId,
        categoryId: item.categoryId,
        beneficiaryName: item.beneficiaryName,
        beneficiaryIcon: item.beneficiaryIcon,
      })
    }

    const isMobile = context.config.device.isMobile

    const handleInputClick = () => {
      setIsOpenList(!isOpenList)
      if (!isVisible) {
        setIsScrolling(true)
        elemRef.current.scrollIntoView({ behavior: `smooth`, block: isMobile ? 'start' : 'center' })
      }
      return
    }

    useEffect(() => {
      if (isOpenList && !isVisible && !isScrolling) {
        handleClosePopover()
      }
      if (isScrolling && isOpenList && isVisible) {
        setIsScrolling(false)
      }
    }, [isOpenList, isVisible, isScrolling])

    return (
      <>
        <InputSearch
          rootRef={rootRef}
          placeholder={!isOpenList && 'Поставщик услуг, оператор'}
          name="search"
          value={value}
          isFullWidth
          onClear={() => setValue(INITIAL_VALUE)}
          onChange={(event) => {
            setValue(event.target.value)
          }}
          onClick={handleInputClick}
          isOpen={isOpenList}
          isDisabled={isDisabled}
        />

        <SearchList
          elemRef={elemRef}
          isOpen={isOpenList}
          onClose={handleClosePopover}
          searchValue={value}
          onClick={handleClick}
        />
      </>
    )
  }
)
