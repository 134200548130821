import React from 'react'
import {
  ContainerButton,
  Header,
  Icon,
  IconSVG,
  BodyStyled,
  Title,
  TitleDisabled,
  DescriptionDisabled,
} from './styled'

export type CardButtonProps = Readonly<{
  className?: string
  /**
   * @default "Текстовое поле"
   */
  children: React.ReactNode
  icon?: React.ReactNode
  iconUrl?: string
  additional?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  iconHoverColor?: string
  iconColor?: string
  isDevelopmentInProgress?: boolean
  isDisabled?: boolean
}>

export const CardButton = (props: CardButtonProps) => {
  const {
    children,
    additional = null,
    icon = null,
    isDevelopmentInProgress,
    iconUrl,
    ...otherProps
  } = props
  if (otherProps.isDisabled) {
    return (
      <ContainerButton {...otherProps}>
        <Header>
          {<IconSVG>{icon}</IconSVG>}
          {additional}
        </Header>
        <TitleDisabled>{children}</TitleDisabled>
        <DescriptionDisabled>{'Временно недоступно'}</DescriptionDisabled>
      </ContainerButton>
    )
  }
  return (
    <ContainerButton {...otherProps}>
      <Header>
        {iconUrl ? <Icon src={iconUrl || ''} /> : <IconSVG>{icon}</IconSVG>}
        {additional}
      </Header>
      {isDevelopmentInProgress && <BodyStyled>В разработке</BodyStyled>}
      <Title>{children}</Title>
    </ContainerButton>
  )
}
