import React, { useState } from 'react'
import { CardButton } from '../CardButton'
import { TransfersListStore } from './PaymentsStore'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ColCustom } from './styled'

export type PaymentsProps = Readonly<{}>

export const PaymentsList = observer((props: PaymentsProps) => {
  const context = useAppContext<AppContext>()
  const transactionsStore = new TransfersListStore(
    context.routes,
    context.config.features.hideDevelopmentInProgress
  )

  return (
    <>
      {transactionsStore.payments.map((item) => {
        return (
          <ColCustom widthSm={3} widthMd={4} widthLg={4} key={item}>
            <CardButton onClick={() => {}} icon={item.icon} iconColor={'#BCC0CC'} isDisabled>
              {item.label}
            </CardButton>
          </ColCustom>
        )
      })}
    </>
  )
})
