import styled from '@emotion/styled'
import { Headline14, Body12, Body14 } from '@platform-ui/typography'
import { ButtonBase } from '@platform-ui/components/ButtonBase'
import DefaultCategoryIcon from '@/features/partners/components/assets/default-category-icon.svg'

export const ContainerButton = styled(ButtonBase)<{ iconHoverColor?: string; iconColor?: string }>(
  ({ theme, iconColor, iconHoverColor }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: theme.color['surface/tertiary'],
    borderRadius: '16px',
    width: '100%',
    minHeight: '100%',
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'normal',
    color: theme.color['text/main'],

    [`svg`]: {
      color: iconColor || theme.color['text/main'],
      transition: 'color 300ms',
    },

    ':hover': {
      [`svg`]: {
        color: iconHoverColor || iconColor || theme.color['text/main'],
      },
    },

    [`${theme.media.md}`]: {
      minHeight: '100%',
    },
  })
)

export const Header = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `12px 12px 0 12px`,
  position: 'relative',
  [`${theme.media.md}`]: {
    padding: `20px 20px 0 20px`,
  },
}))

export const Title = styled(Headline14)(({ theme }) => ({
  padding: `0 12px 12px 12px`,
  textAlign: 'left',

  [`${theme.media.md}`]: {
    ...theme.typography['headline/16'],
    padding: `2px 20px 20px 20px`,
  },
}))

export const TitleDisabled = styled(Headline14)(({ theme }) => ({
  padding: `0 12px 12px 12px`,
  textAlign: 'left',
  marginTop: '20px',
  color: theme.color['text/secondary'],
  [`${theme.media.md}`]: {
    ...theme.typography['headline/16'],
    marginTop: '20px',
    padding: `0 20px 0px 20px`,
  },
}))

export const DescriptionDisabled = styled(Body14)(({ theme }) => ({
  color: theme.color['icons/secondary'],
  textAlign: 'left',
  padding: '12px 12px 4px',
  fontWeight: '500',
  width: '50%',
  marginTop: 'auto',
  marginBottom: 20,
  lineHeight: '12px',
  fontSize: 10,

  [`${theme.media.md}`]: {
    padding: '0 20px 4px',
  },
}))

export const BodyStyled = styled(Body12)(({ theme }) => ({
  color: theme.color['icons/secondary'],
  textAlign: 'left',
  padding: '12px 12px 4px',
  fontWeight: '500',
  marginTop: 'auto',
  lineHeight: '12px',
  fontSize: 10,

  [`${theme.media.md}`]: {
    padding: '0 20px 4px',
  },
}))

export const IconSVG = styled.div()

export const Icon = styled.img(({ theme }) => ({
  width: '40px',
  height: '40px',
  minWidth: '40px',
  minHeight: '40px',
  borderRadius: theme.borderRadius.r10,
  position: 'relative',

  '::before': {
    content: '""',
    position: 'absolute',
    left: theme.spacing.s10,
    top: theme.spacing.s10,
    transform: 'translate(-50%, -50%)',
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    borderRadius: theme.borderRadius.r10,
    boxSizing: 'content-box',
  },
}))
