import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'
import { CategoriesModel } from '../CategoriesModel'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }
  try {
    const successData = data as AdapterModel.ResponseDataSuccess
    const categories: CategoriesModel.Category[] = successData.Result.map((category) => {
      return {
        categoryID: category.CategoryID,
        categoryName: category.CategoryName,
        imageCategoryUrl: `https://sales-father-images-nginx.homecredit.ru/images/${category.ImageCategoryUrl}`,
      }
    })
    return {
      type: ResponseType.ResolveWithData,
      categories,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
