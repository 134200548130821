import React, { forwardRef, MouseEventHandler, MouseEvent, useRef } from 'react'
import { useFocus, useForkRef } from '@platform-ui/utils'
import { ClearIcon } from '@platform-ui/icons/ClearIcon'
import { SearchIcon } from '@platform-ui/icons/SearchIcon'
import { PureInputProps } from '@platform-ui/components/PureInputProps'
import {
  InputSearchWrapper,
  InputCustom,
  InputSearchLeftSlot,
  InputSearchRightSlot,
  ClearButton,
} from './styled'

export interface InputSearchProps
  extends Omit<
    PureInputProps,
    'placeholder' | 'name' | 'value' | 'isFullWidth' | 'onClear' | 'onChange'
  > {
  onClear: MouseEventHandler<HTMLButtonElement>
  type?: 'text'
  isAutoFocus?: boolean
}

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>((props, ref): any => {
  const {
    rootRef,
    onClear,
    type = 'text',
    isAutoFocus = false,
    value = '',
    name,
    isOpen,
    isDisabled,
    ...otherProps
  } = props
  const [isFocusInput, inputFocusProps] = useFocus<HTMLInputElement>({ isAutoFocus })
  const [isFocusCloseButton, closeButtonFocusProps, closeButtonFocusApi] =
    useFocus<HTMLButtonElement>()

  const inputRef = useRef<HTMLInputElement>()
  const inputForkedRef = useForkRef(ref, inputRef)

  const handleClear = (event: MouseEvent<HTMLButtonElement>) => {
    closeButtonFocusApi(false)
    if (onClear) {
      onClear(event)
    }
    inputRef.current?.focus()
  }

  const handleBlur: InputSearchProps['onBlur'] = (e) => {
    inputFocusProps.onBlur(e)
  }

  const handleFocus: InputSearchProps['onFocus'] = (e) => {
    inputFocusProps.onFocus(e)
  }

  const isVisibleClearButton =
    typeof onClear === 'function' && value.length > 0 && (isFocusInput || isFocusCloseButton)

  return (
    <InputSearchWrapper ref={rootRef}>
      <InputCustom
        ref={inputForkedRef}
        name={name}
        type={type}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isReadOnly={!isOpen}
        isFocusInput={isFocusInput}
        isOpen={!!isOpen}
        isDisabled={isDisabled}
        {...otherProps}
      />

      <InputSearchLeftSlot>
        <SearchIcon />
      </InputSearchLeftSlot>

      <InputSearchRightSlot isOpen={isVisibleClearButton}>
        <ClearButton
          type="button"
          onClick={handleClear}
          aria-label="Очистить"
          {...closeButtonFocusProps}
        >
          <ClearIcon size="s10" />
        </ClearButton>
      </InputSearchRightSlot>
    </InputSearchWrapper>
  )
})
