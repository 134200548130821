import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { CategoriesModel } from './CategoriesModel'
import { adapter } from './adapters/adapter'

export const categories: CategoriesModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.pms.categories,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    CategoriesModel.ResponseData,
    void,
    CategoriesModel.RequestHeaders,
    CategoriesModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

export function categoriesQueryKey() {
  return '/v3/beneficiary/categories'
}

export function useCategoriesQuery<
  T extends CategoriesModel.ResponseData = CategoriesModel.ResponseData
>(
  props: CategoriesModel.Props,
  options?: Omit<
    UseQueryOptions<CategoriesModel.ResponseData, CategoriesModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  >
) {
  const key = categoriesQueryKey()
  return useQuery(key, () => categories({ ...props }).then((res) => res.data), {
    refetchOnWindowFocus: false,
    retry: 0,
    ...options,
  })
}
