import React from 'react'
import { TColProps } from '@platform-ui/components/Col'
import { makeAutoObservable } from 'mobx'
import { LinkProps } from '@/next/link'
import { MobileIcon } from '@platform-ui/icons/MobileIcon'
import { WifiIcon } from '@platform-ui/icons/WifiIcon'
import { PaymentCreditIcon } from '@platform-ui/icons/PaymentCreditIcon'
import { GosuslugiIcon } from '@platform-ui/icons/GosuslugiIcon'
import { ServicesIcon } from '@platform-ui/icons/ServicesIcon'
import { MenuOffersHomeIcon } from '@platform-ui/icons/MenuOffersHomeIcon'
import { MenuOffersMenuIcon } from '@platform-ui/icons/MenuOffersMenuIcon'
// import { SbpIcon } from './assets/SbpIcon'
import GamesIcon from '../../assets/games-icon.svg'

import { RoutesImport } from '@/routes/RoutesImport'
import { isBrowser } from '@platform-ui/utils'

export class TransfersListStore {
  private static instance: TransfersListStore
  private static exists = false
  constructor(private routes: RoutesImport, private hideDevelopmentInProgress: boolean) {
    if (TransfersListStore.exists) {
      return TransfersListStore.instance
    }
    TransfersListStore.instance = this
    TransfersListStore.exists = isBrowser

    makeAutoObservable(this)
  }

  init(initialProps: {}) {
    if (!initialProps) {
      return
    }
  }

  get payments(): {
    href?: LinkProps['href']
    label: string
    icon: JSX.Element
    additional?: JSX.Element
    iconColor: string
    colWidthSm?: TColProps['widthSm']
    colWidthMd?: TColProps['widthMd']
    colWidthLg?: TColProps['widthLg']
    isDevelopmentInProgress: boolean
  }[] {
    return [
      {
        href: '#',
        label: 'Моб. связь',
        icon: <MobileIcon size="s16" />,
        iconColor: '#E07293',
        colWidthSm: 4,
        colWidthMd: 6,
        colWidthLg: 8,
        isDevelopmentInProgress: this.hideDevelopmentInProgress,
      },
      {
        href: '#',
        label: 'Интернет и ТВ',
        icon: <WifiIcon size="s16" />,
        iconColor: '#E58668',
        colWidthSm: 2,
        colWidthMd: 3,
        colWidthLg: 4,
        isDevelopmentInProgress: this.hideDevelopmentInProgress,
      },
      {
        label: 'ЖКХ',
        icon: <MenuOffersHomeIcon size="s16" />,
        iconColor: '#E2AF62',
        colWidthSm: 3,
        colWidthMd: 3,
        colWidthLg: 4,
        isDevelopmentInProgress: this.hideDevelopmentInProgress,
      },
      {
        href: '/offers',
        label: 'Оплата кредитов',
        icon: <PaymentCreditIcon size="s16" />,
        iconColor: '#9FB660',
        colWidthSm: 3,
        colWidthMd: 3,
        colWidthLg: 4,
        isDevelopmentInProgress: this.hideDevelopmentInProgress,
      },
      {
        href: '/menu',
        label: 'Госуслуги',
        icon: <GosuslugiIcon size="s16" />,
        iconColor: '#81AF8B',
        colWidthSm: 2,
        colWidthMd: 3,
        colWidthLg: 4,
        isDevelopmentInProgress: this.hideDevelopmentInProgress,
      },
      {
        href: '/menu',
        label: 'Сервисы оплаты',
        icon: <ServicesIcon size="s16" />,
        iconColor: '#61ADAD',
        colWidthSm: 2,
        colWidthMd: 3,
        colWidthLg: 4,
        isDevelopmentInProgress: this.hideDevelopmentInProgress,
      },
      {
        href: '/menu',
        label: 'Игры',
        icon: <img src={GamesIcon} width={30} />,
        iconColor: '#738195',
        colWidthSm: 2,
        colWidthMd: 3,
        colWidthLg: 4,
        isDevelopmentInProgress: this.hideDevelopmentInProgress,
      },
      {
        href: '/menu',
        label: 'Прочее',
        icon: <MenuOffersMenuIcon size="s16" />,
        iconColor: '#738195',
        colWidthSm: 2,
        colWidthMd: 3,
        colWidthLg: 4,
        isDevelopmentInProgress: this.hideDevelopmentInProgress,
      },
    ]
  }
}
