import React, { useEffect } from 'react'
import Head from '@/next/head'
import { AppPage } from '@/models/AppPage'
import DefaultPage from '@/layouts/DefaultPage'
import { TransfersList } from '@/features/transfers-list'
import { customTitle } from '@/utils/customTitle'
import { TransfersModal } from '@/features/transfers-modal'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'
// import styled from '@emotion/styled'
// import { Row } from '@platform-ui/components/Row'
// import { Col } from '@platform-ui/components/Col'
// import { FavoriteTransactions } from '@/features/favorite-transactions'

// const Title = styled.h3(({ theme }) => ({
//   paddingLeft: '12px',
//   ...theme.typography['headline/20'],
//   marginBottom: '20px',
//
//   [`${theme.media.md}`]: {
//     ...theme.typography['headline/28'],
//     marginBottom: '20px',
//   },
// }))

// const RowCustom = styled(Row)(({ theme }) => {
//   return {
//     marginBottom: '16px',
//   }
// })
//
// const FavoriteTransactionsCustom = styled(FavoriteTransactions)(() => {
//   return {
//     marginBottom: '16px',
//   }
// })

const Page: AppPage = observer(() => {
  const { popups, eventBus } = useAppContext<AppContext>()
  useEffect(() => {
    if (popups.screens?.payment.length > 0) {
      eventBus.popupModal.open.emit({
        popup: popups.screens.payment[0],
      })
    }
  }, [popups])

  return (
    <>
      <Head>
        <title>{customTitle('Переводы и платежи')}</title>
      </Head>
      <DefaultPage>
        {/* <RowCustom>
          <Col widthSm={6}>
            <Title>Избранное</Title>
          </Col>
          <Col widthSm={6} widthMd={12} widthLg={16}>
            <FavoriteTransactionsCustom />
          </Col>
        </RowCustom> */}
        <TransfersList />
        <TransfersModal />
      </DefaultPage>
    </>
  )
})

export default Page
