import styled from '@emotion/styled'
import { PureInput } from '@platform-ui/components/PureInput'
import { ButtonBase } from '@platform-ui/components/ButtonBase'
import { Fade } from '@platform-ui/components/effects/Fade'
import { PureInputProps } from '@platform-ui/components/PureInput'

export const InputSearchWrapper = styled.div<{
  isFocusInput: boolean
}>(({ theme }) => {
  return {
    position: 'relative',
    marginBottom: '16px',

    [`${theme.media.md}`]: {
      marginBottom: '24px',
    },
  }
})

export const InputCustom = styled(PureInput)<Required<Pick<PureInputProps, 'isFullWidth'>>>(
  ({ theme, isOpen }) => {
    return {
      background: !isOpen && theme.color['surface/tertiary'],
      paddingLeft: 35,
      paddingRight: 45,
      borderRadius: isOpen && '12px 12px 0 0',
      height: 56,

      '::placeholder': {
        color: theme.color['text/secondary'],
      },

      ':not(:disabled)': {
        ':focus, :active': {
          paddingLeft: 34,
          paddingRight: 44,
        },
      },
    }
  }
)

export const InputSearchLeftSlot = styled.div(() => ({
  position: 'absolute',
  left: 8,
  bottom: 0,
  top: 0,
  margin: 'auto',
  height: 24,
  width: 24,
}))

export const InputSearchRightSlot = styled(Fade)(() => {
  return {
    position: 'absolute',
    right: 8,
    top: 0,
    bottom: 0,
    height: 24,
    width: 24,
    margin: 'auto',
  }
})

export const ClearButton = styled(ButtonBase)(({ theme }) => ({
  width: '24px',
  height: '24px',
  padding: 0,
  borderRadius: '50%',
  border: 'none',
  background: theme.color['icons/mainTransparent'],
  color: theme.color['background/main'],
  cursor: 'pointer',
}))
