import React, { useState } from 'react'
import { CardButton } from './components/CardButton'
import { TransfersListStore } from './TransfersListStore'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Col } from '@platform-ui/components/Col'
import { Button } from '@platform-ui/components/Button'
import { ProductInDevelopmentDialog } from '../product-lists/components/ProductInDevelopmentDialog'
import { TRANSACTION_TYPE } from './constants'
import { useCategoriesQuery } from '@/api/pms/categories'
import { useSettingsQuery } from '@/api/settings/settings'
import {
  RowCustom,
  Title,
  ColCustom,
  TransferShimmer,
  ErrorText,
  ErrorWrapper,
  TransferTitleShimmer,
} from './styled'
import { SearchBeneficiaries } from '@/features/search-beneficiaries'
import { PaymentsList } from './components/PaymentsList'
import { TransferByCardModal } from '@/components/TransferByCardModal'

export type TransfersListProps = Readonly<{}>

export const TransfersList = observer((props: TransfersListProps) => {
  const context = useAppContext<AppContext>()
  const transactionsStore = new TransfersListStore(
    context.routes,
    context.config.features.hideDevelopmentInProgress
  )
  const { config } = context
  const { data: settingsData } = useSettingsQuery()
  const { data, isError, isLoading, refetch } = useCategoriesQuery({
    apiVersion: config.apiVersion,
  })
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenPayByCardModal, setIsOpenPayByCardModal] = useState(false)
  const shimmerData = Array.from({ length: 8 }).map((_, i) => i)

  return (
    <>
      <RowCustom>
        <Col widthSm={6}>
          <Title>Переводы</Title>
        </Col>
        {transactionsStore.transactions.map((item, index) => (
          <ColCustom
            widthSm={item.colWidthSm}
            widthMd={item.colWidthMd}
            widthLg={item.colWidthLg}
            key={index}
          >
            <CardButton
              iconColor={item.iconColor}
              onClick={() => {
                if (
                  item.type === TRANSACTION_TYPE.byPhone ||
                  item.type === TRANSACTION_TYPE.betweenAccounts
                ) {
                  context.eventBus.transfersModal.open.emit({
                    type: item.type,
                    title: item.label,
                  })
                  return
                }
                if (item.type === TRANSACTION_TYPE.byCard) {
                  setIsOpenPayByCardModal(true)
                  return
                }
                setIsOpenModal(true)
              }}
              icon={item.icon}
              additional={item.additional}
              isDevelopmentInProgress={item.isDevelopmentInProgress}
            >
              {item.label}
            </CardButton>
          </ColCustom>
        ))}
      </RowCustom>

      <RowCustom>
        <Col widthSm={6}>
          <Title>Платежи</Title>
        </Col>
        {!!data?.categories.length && (
          <Col widthSm={6}>
            <SearchBeneficiaries
              isDisabled={!settingsData?.result?.enablePaymentManagerServiceApiV3}
            />
          </Col>
        )}

        {settingsData?.result?.enablePaymentManagerServiceApiV3 ? (
          <>
            {isLoading && (
              <>
                <Col widthSm={6}>
                  <TransferTitleShimmer />
                </Col>
                {shimmerData.map((number) => {
                  return (
                    <ColCustom widthSm={8} widthMd={4} widthLg={4} key={number}>
                      <TransferShimmer />
                    </ColCustom>
                  )
                })}
              </>
            )}
            {isError && (
              <Col widthSm={6}>
                <ErrorWrapper>
                  <ErrorText>Не удалось загрузить список платежей</ErrorText>
                  <Button onClick={() => refetch()} sizeSm={'m'} view="ghost">
                    Попробовать снова
                  </Button>
                </ErrorWrapper>
              </Col>
            )}
            {!!data?.categories.length &&
              data.categories.map((item) => {
                return (
                  <ColCustom widthSm={3} widthMd={4} widthLg={4} key={item.categoryID}>
                    <CardButton
                      onClick={() => {
                        context.eventBus.transfersModal.open.emit({
                          type: TRANSACTION_TYPE.payments,
                          title: item.categoryName,
                          categoryId: item.categoryID,
                        })
                      }}
                      iconUrl={item.imageCategoryUrl}
                    >
                      {item.categoryName}
                    </CardButton>
                  </ColCustom>
                )
              })}
          </>
        ) : (
          <PaymentsList />
        )}
      </RowCustom>
      <TransferByCardModal
        isOpen={isOpenPayByCardModal}
        onClose={() => setIsOpenPayByCardModal(false)}
      />
      <ProductInDevelopmentDialog isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </>
  )
})
